<template>
  <div v-if="option === 'type'">
    <select
      ref="refSelect"
      @change="change($event)"
      aria-label=".form-select type"
      class="w-full"
    >
      <option value="-1">Select</option>
      <option
        v-for="(asset, idx) in type_ds"
        :key="idx"
        :value="JSON.stringify(asset)"
        >{{ asset.type }}</option
      >
    </select>
  </div>

  <div v-else-if="option === 'symbol'">
    <select
      ref="refSelect"
      @change="change($event)"
      aria-label=".form-select type"
      class="w-full"
    >
      <option value="-1">Select</option>
      <option
        v-for="(asset, idx) in symbol_ds"
        :key="idx"
        :value="JSON.stringify(asset)"
        >{{ asset.symbol }}</option
      >
    </select>
  </div>
</template>

<script>
import OpenapiService from "@/services/openapi.service.js";

export default {
  props: {
    option: String,
    category: Number,
    blockchain_id: [Number, String],
    contract_type: String,
    order_type: String,
  },

  emits: {
    changeType: [Number, String],
    changeAsset: [Number, String],
  },

  data: function() {
    return {
      type_ds: [],
      symbol_ds: [],
    };
  },

  watch: {
    blockchain_id: function() {
      console.log("this.blockchain_id: ", this.blockchain_id, this.category);

      this.type_ds = [];
      this.symbol_ds = [];

      if (this.blockchain_id === "") {
        this.$emit("changeType", "");
        this.$emit("changeAsset", "");
      } else {
        if (this.category === 1) {
          if (this.order_type === "auction") {
            console.log("order_type is auction");

            OpenapiService.getAssetsByType(this.blockchain_id, "ERC20").then(
              (res) => {
                this.symbol_ds = res.data;
              }
            );
          } else {
            OpenapiService.getAssetsByCategory(
              this.blockchain_id,
              this.category
            ).then((res) => {
              this.symbol_ds = res.data;
            });
          }
        } else if (this.category === 2) {
          OpenapiService.getAssetsByCategory(
            this.blockchain_id,
            this.category
          ).then((res) => {
            this.type_ds = res.data;
          });
        }
      }
    },

    contract_type: function() {
      this.symbol_ds = [];

      if (this.contract_type === "") {
        this.$emit("changeAsset", "");
      } else {
        OpenapiService.getAssetsByCategory(
          this.blockchain_id,
          this.category
        ).then((res) => {
          res.data.forEach((asset) => {
            if (asset.type === this.contract_type) {
              this.symbol_ds.push(asset);
            }
          });
        });
      }
    },
  },

  methods: {
    change(e) {
      console.log("e.target.value ==> ",e.target.value );
      if (e.target.value === "-1") {
        if (this.option === "type") {
          this.$emit("changeType", "");
        } else if (this.option === "symbol") {
          this.$emit("changeAsset", "");
        }
      } else {
        if (this.option === "type") {
          const selected_item = JSON.parse(e.target.value);
          this.$emit("changeType", selected_item.type);
        } else if (this.option === "symbol") {
          const selected_item = JSON.parse(e.target.value);
          this.$emit("changeAsset", selected_item);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  padding: 0;
}
</style>
