<template>
  <div v-if="wallets.length !== 0">
    <!-- <div v-for="wallet in wallets" :key="wallet.id">
      <div class="cursor-pointer box mt-1" @click="walletSelect(wallet)">
        <div
          class="flex items-center border-b border-gray-200 dark:border-dark-5 p-5"
        >
          <div class="w-full">
            <div class="mt-1 ml-5">{{ wallet.account }}</div>
          </div>
        </div>
      </div>
    </div> -->

    <select
      @change="change($event)"
      aria-label=".form-select type"
      class="w-full"
    >
      <option value="-1">Select</option>
      <option
        v-for="(wallet, index) in wallets"
        :key="index"
        :value="JSON.stringify(wallet)"
        >{{ wallet.account }}</option
      >
    </select>
  </div>
</template>

<script>
import OpenapiAuthService from "@/services/openapiAuth.service";

export default {
  props: { modelValue: { type: String, required: true }, userId: Number },
  emits: ["update:modelValue"],

  data: function() {
    return {
      wallets: [],
    };
  },

  created() {
    this.load();
  },

  methods: {
    load: function() {
      OpenapiAuthService.walletList(this.userId).then((res) => {
        this.wallets = res.data.items;
      });
    },

    change(e) {
      if (e.target.value === "-1") {
        this.$emit("update:modelValue", "");
      } else {
        const selected_item = JSON.parse(e.target.value);
        this.$emit("update:modelValue", selected_item.account);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  padding: 0;
}
</style>
