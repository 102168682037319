<template>
  <div v-if="type === 'MP4'" class="w-full h-full">
    <video
      ref="video"
      :src="filePath"
      class="h-full w-full"
      :class="[
        shape,
        objectFit,
        { radius__left: radiusLeft },
        { radius__top: radiusTop },
      ]"
      @mouseover="controlVideo"
      @mouseout="controlVideo"
      alt="None"
      muted
      loop
      playsinline
    />
  </div>
  <div v-else-if="type === null">
    <p>File type is empty</p>
  </div>
  <div v-else class="w-full h-full">
    <img
      v-if="!filePath"
      :src="pic"
      class="w-full h-full bg-white"
      :class="[
        shape,
        objectFit,
        { radius__left: radiusLeft },
        { radius__top: radiusTop },
      ]"
      alt="None"
    />
    <img
      v-else
      :src="filePath"
      class="w-full h-full"
      alt="None"
      :class="[
        shape,
        objectFit,
        { radius__left: radiusLeft },
        { radius__top: radiusTop },
      ]"
    />
  </div>
</template>

<script>
import pic from "@/assets/img/basic_image.png";

export default {
  props: {
    filePath: {
      type: String,
      required: true,
    },

    fileType: {
      type: String,
    },

    autoplay: {
      type: Boolean,
      default: true,
    },

    shape: {
      type: String,
      default: null,
    },

    objectFit: {
      type: String,
      default: "object-cover",
    },

    radiusLeft: {
      type: Boolean,
      default: false,
    },

    radiusTop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pic: pic,
      type: null,
    };
  },

  created() {
    // fileType setting
    if (!this.fileType) {
      const filePath = this.filePath;
      const lastDot = filePath.lastIndexOf(".");

      if (lastDot < 1) {
        this.type = "";
      } else {
        const fileExt = filePath.substring(lastDot + 1).toUpperCase();

        if (fileExt === "MP4") {
          this.type = "MP4";
        } else {
          this.type = fileExt;
        }
      }
    } else {
      this.type = this.fileType;
    }
  },

  mounted() {
    // autoplay setting
    if (this.type === "MP4" && this.autoplay) {
      this.$refs.video.autoplay = this.autoplay;
    }
  },

  methods: {
    controlVideo() {
      if (!this.autoplay) {
        const video = this.$refs.video;

        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

// .border-t-26 {
//   border-top: 0.5px solid #262626 !important;
// }

// .hide-scrollbar {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }
// .hide-scrollbar::-webkit-scrollbar {
//   display: none; /* Chrome, Safari, Opera*/
// }

.radius__left {
  border-radius: $radius 0 0 $radius;
}

.radius__top {
  border-radius: $radius $radius 0 0;
}
</style>
