<template>
  <div>
    <section v-if="loading" class="flex h-full justify-center items-center">
      <img
        src="@/assets/img/cilo_loading.gif"
        alt="loading"
        class="w-12 h-12 md:w-20 md:h-20 xl:w-28 xl:h-28"
      />
    </section>

    <label v-else class="h-full cursor-pointer">
      <div
        v-if="!modelValue"
        class="flex flex-col h-full justify-center items-center"
      >
        <svg
          class="w-8 h-8"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
          />
        </svg>
        <span class="mt-2">SELECT A FILE</span>
      </div>

      <div v-else class="flex h-full justify-center items-center">
        <content-viewer :file-path="modelValue" object-fit="object-contain" />
      </div>

      <input
        type="file"
        class="hidden cursor-pointer"
        accept=".png, .jpeg, .jpg, .gif, .mp4"
        @input="onHandleChange"
      />
    </label>
  </div>
</template>

<script>
import ContentViewer from "@/components/ContentViewer.vue";
import UploadService from "@/services/upload.service.js";
import { notify } from "@kyvg/vue3-notification";

export default {
  props: {
    modelValue: { type: String, required: true },
    owner_nickname: { type: String, required: true },
    sub_bucket: { type: String, required: true },
  },

  emits: ["update:modelValue", "uploaded"],

  components: {
    ContentViewer,
  },

  data: function() {
    return {
      ds: [],
      loading: false,
    };
  },

  computed: {
    inputVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },

  methods: {
    onHandleChange(event) {
      if (!this.owner_nickname) {
        notify({ type: "error", text: "Owner nickname is empty" });

        //오너 아이디 입력 후 다시 같은 그림(value)를 입력할 수 있도록 하기 위해서 input이 value를 null 처리함.
        event.target.value = null;
      } else {
        const file = event.target.files[0];
        this.loading = true;

        MediaInfo(
          {
            format: "JSON",
          },
          (mediaInfo) => {
            if (file) {
              const reader = new FileReader();
              const getSize = () => file.size;
              const { VUE_APP_STAGE: stage } = process.env;

              const readChunk = (chunkSize, offset) =>
                new Promise((resolve, reject) => {
                  reader.onload = (event) => {
                    if (event.target.error) {
                      reject(event.target.error);
                    }
                    resolve(new Uint8Array(event.target.result));
                  };

                  reader.readAsArrayBuffer(
                    file.slice(offset, offset + file.size)
                  );
                });

              mediaInfo
                .analyzeData(getSize, readChunk)
                .then((info) => {
                  UploadService.media(
                    file,
                    this.owner_nickname,
                    this.sub_bucket,
                    info,
                    reader,
                    stage
                  ).then((res) => {
                    this.loading = false;

                    const imgPath =
                      stage === "prod"
                        ? "https://cilo-nft-prod-contents.s3.ap-northeast-2.amazonaws.com/" +
                          res.path
                        : "https://cilo-nft-staging-contents.s3.ap-northeast-2.amazonaws.com/" +
                          res.path;

                    this.inputVal = imgPath;
                    this.$emit("uploaded", {
                      id: res.id,
                      type: res.type,
                      name: res.name,
                      path: imgPath,
                      done: true,
                    });

                    UploadService.updateSate(res.id).then((res2) => {
                      console.log("updateSteate res2 ==> ", res2);
                    });
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          }
        );
      }
    },
  },
};
</script>
