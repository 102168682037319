<template>
  <div>
    <select @change="changed($event)" class="w-full">
      <option value="-1">Select</option>
      <option
        v-for="(blockchain, idx) in ds"
        :key="idx"
        :value="JSON.stringify(blockchain)"
        >{{ blockchain.name }}</option
      >
    </select>
  </div>
</template>
<script>
import OpenapiService from "@/services/openapi.service.js";

export default {
  emits: {
    changeNetwork: [String, Number],
  },

  created: function() {
    this.load();
  },

  data: function() {
    return {
      ds: [],
    };
  },

  methods: {
    load() {
      OpenapiService.getBlockchainList().then((res) => {
        this.ds = res.data;
      });
    },

    init: function() {
      this.ds = [];
      this.load();
    },

    changed(e) {
      if (e.target.value === "-1") {
        this.$emit("changeNetwork", "");
      } else {
        const selected_item = JSON.parse(e.target.value);
        this.$emit("changeNetwork", selected_item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  padding: 0;
}
</style>
