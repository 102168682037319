<template>
  <div v-if="user_is_creator !== 1" class="min-h-screen">
    Only artist can access this page.
  </div>
  <div v-else class="form__adjustment">
    <h1 class="font-semibold md:text-lg lg:text-xl mb-10">
      Create NFT
    </h1>

    <section class="relative">
      <div>
        <h2 class="text-lg font-semibold my-8">INFORMATION</h2>

        <div class="flex mt-2">
          <label for="artwork" class="w-1/4">Artwork</label>
          <image-uploader
            id="artwork"
            v-model="image"
            :owner_nickname="nickname"
            sub_bucket="product"
            @uploaded="imageUploaded"
            class="cursor-pointer border parula__border-black w-3/4 h-64"
          />
        </div>

        <div class="flex mt-2">
          <label for="thumbnail" class="w-1/4">Thumbnail</label>
          <image-uploader
            id="thumbnail"
            v-model="thumbnail"
            :owner_nickname="nickname"
            sub_bucket="product"
            @uploaded="thumbnailUploaded"
            class="cursor-pointer border parula__border-black w-3/4 h-64"
          />
        </div>

        <div class="flex mt-2">
          <label for="title" class="w-1/4">Title</label>
          <input
            id="title"
            v-model="title"
            placeholder="Title"
            type="text"
            class="
              focus:outline-none
              input-design
            "
          />
        </div>

        <div class="flex mt-2">
          <label for="description" class="w-1/4">Description</label>
          <textarea
            id="description"
            v-model="description"
            placeholder="Description"
            type="text"
            rows="5"
            class="
              focus:outline-none
              input-design
            "
          />
        </div>

        <div class="flex mt-2">
          <div class="w-1/4">
            Category
          </div>
          <!-- @change="change($event)" -->
          <select
            v-model="category"
            class="
              focus:outline-none
              input-design
              cursor-default
            "
          >
            <!-- TODO -->
            <!-- select에 value 컴포넌트로 빼면 "-1"로 하고 ""을 emit -->
            <!-- v-model로 emit 없이 하도록 refactoring ! -->
            <option value="">Select</option>
            <option value="fine">Fine Art</option>
            <option value="coll">Collaboration</option>
          </select>
        </div>
      </div>

      <h2 class="text-lg font-semibold my-8">ARTIST INFORMATION</h2>
      <div class="flex mt-2">
        <label for="name" class="w-1/4">Name</label>
        <input
          id="name"
          v-model="name"
          placeholder="Artist Name"
          type="text"
          class="
            focus:outline-none
            input-design
            parula__bg-gray-soft
            cursor-default
          "
          readonly
        />
      </div>

      <div class="flex mt-2">
        <label for="nickname" class="w-1/4">Nickname</label>
        <input
          id="nickname"
          v-model="nickname"
          placeholder="Artist Nickname"
          type="text"
          class="
            focus:outline-none
            input-design
            parula__bg-gray-soft
            cursor-default
          "
          readonly
        />
      </div>

      <div class="flex mt-2">
        <label for="pen_name" class="w-1/4">Pen Name</label>
        <input
          id="pen_name"
          v-model="pen_name"
          placeholder="Artist Pen Name"
          type="text"
          class="
            focus:outline-none
            input-design
            parula__bg-gray-soft
            cursor-default
          "
          readonly
        />
      </div>

      <div class="flex mt-2">
        <div class="w-1/4">
          Minting Account
        </div>
        <wallet-selector
          v-if="user_id !== ''"
          v-model="init_owner_wallet"
          :user-id="user_id"
          class="focus:outline-none input-design"
        />
      </div>

      <h2 class="text-lg font-semibold my-8">BLOCKCHAIN</h2>
      <div class="flex mt-2">
        <label for="network" class="w-1/4">Network</label>
        <blockchain-selector
          id="network"
          ref="BlockchainSelector"
          class="focus:outline-none input-design"
          @changeNetwork="changeNetwork"
        />
      </div>

      <div class="flex mt-2">
        <label for="network" class="w-1/4">Contract Type</label>
        <asset-selector
          id="contract_type"
          :option="'type'"
          :blockchain_id="blockchain_id"
          :category="2"
          @changeType="changeType"
          class="focus:outline-none input-design"
        />
      </div>

      <div class="flex mt-2">
        <label for="asset" class="w-1/4">Asset</label>
        <asset-selector
          id="asset"
          option="symbol"
          :blockchain_id="blockchain_id"
          :contract_type="contract_type"
          :category="2"
          @changeAsset="changeAsset"
          class="focus:outline-none input-design"
        />
      </div>

      <h2 class="text-lg font-semibold my-8">ISSUE</h2>
      <div class="flex mt-2">
        <label for="total_supply" class="w-1/4">Supply</label>
        <input
          id="total_supply"
          v-model="total_supply"
          placeholder="Supply"
          type="number"
          min="1"
          max="100000"
          step="1"
          class="
            focus:outline-none
            input-design
          "
          :class="{ 'parula__bg-gray-soft': contract_type === 'ERC721' }"
          :disabled="contract_type === 'ERC721'"
        />
      </div>

      <div class="flex mt-2">
        <label for="royalties" class="w-1/4">Royalties</label>
        <input
          id="royalties"
          v-model="royalties"
          placeholder="7.00 ~ 9.99%"
          type="number"
          min="7"
          max="9.99"
          step="0.01"
          class="
            focus:outline-none
            input-design
          "
        />
      </div>
    </section>

    <!-- button -->
    <section class="w-full flex justify-end gap-x-2 mt-8">
      <router-link to="/mypage" class="button__border__green"
        >Cancel
      </router-link>
      <button class="button__green" @click="onMint">
        Create
      </button>
    </section>

    <loading-modal ref="LoadingModal" />

    <result-modal
      ref="ResultModal"
      :msg="'Artwork has successfully minted.'"
      :modalFunction="init"
    />
  </div>

  <notifications position="bottom right" />
</template>

<script>
import OpenapiService from "@/services/openapi.service";
import OpenapiAuthService from "@/services/openapiAuth.service";
import SessionService from "@/services/session.service.js";

import ImageUploader from "@/components/ImageUploader.vue";
import AssetSelector from "@/views/my-page/sub/AssetSelector.vue";
import BlockchainSelector from "@/views/my-page/sub/BlockchainSelector.vue";
import WalletSelector from "@/views/my-page/sub/WalletSelector.vue";
import LoadingModal from "@/components/modal/LoadingModal.vue";
import ResultModal from "@/components/modal/ResultModal.vue";

// import detectEthereumProvider from "@metamask/detect-provider";
import { notify } from "@kyvg/vue3-notification";
import { Parula } from "@/parula-js/src/parula.js";

export default {
  data() {
    return {
      user_is_creator: "",
      metamask_blockchain_id: "",
      user_id: "",

      // mint params
      image: "",
      thumbnail: "",
      title: "",
      description: "",
      category: "",

      main_content_id: "",
      main_content_type: "",
      sub_content_id: "",
      sub_content_type: "",

      blockchain_id: "",
      contract_type: "",
      asset_id: "",
      total_supply: "",
      royalties: "",

      name: "",
      nickname: "",
      pen_name: "",
      init_owner_wallet: "",
      init_mint_wallet: "",
      centuple_royalties: "",

      token_id: "",
      metadata_url: "",
    };
  },

  components: {
    ImageUploader,
    AssetSelector,
    BlockchainSelector,
    WalletSelector,
    LoadingModal,
    ResultModal,
  },

  async created() {
    this.user_id = await SessionService.getUserId();
    this.metamask_blockchain_id = Number(window.ethereum.chainId);

    await this.loadUser();

    window.ethereum.on("chainChanged", this.handleChainIdChained);
    // this.checkWallet();
    // window.ethereum.on("accountsChanged", this.handleAccountsChanged);
  },

  methods: {
    init() {
      this.$router.push("/mypage");
      // // mint params
      // this.image = "";
      // this.thumbnail = "";
      // this.title = "";
      // this.description = "";

      // this.main_content_id = "";
      // this.main_content_type = "";
      // this.sub_content_id = "";
      // this.sub_content_type = "";

      // this.blockchain_id = "";
      // this.contract_type = "";
      // this.asset_id = "";
      // this.total_supply = "";
      // this.royalties = "";

      // this.name = "";
      // this.nickname = "";
      // this.pen_name = "";
      // this.init_owner_wallet = "";
      // this.init_mint_wallet = "";
      // this.centuple_royalties = "";

      // this.metadata_url = "";
    },

    // change(e) {
    //   console.log("e.target.value ==> ", e.target.value);
    //   if (e.target.value === "-1") {
    //     this.category = "";
    //   } else {
    //     const selected_item = e.target.value;
    //     this.category = selected_item;
    //   }
    // },

    async onMint() {
      const validation = await this.checkValidation();
      if (!validation) return;

      try {
        this.$refs.LoadingModal.open();

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const minterWallet = accounts[0];
        this.init_mint_wallet = minterWallet;

        const parula = new Parula(window.ethereum, {}, (line) =>
          console.info("[*] ", line)
        );

        let royaltiesTo = [];

        if (this.royalties > 0) {
          this.centuple_royalties = Number((this.royalties * 100).toFixed(2));
          royaltiesTo = [[this.init_owner_wallet, this.centuple_royalties]];
        }

        this.metadata_url = `${process.env.VUE_APP_METADATA_URL}/metadata/${
          this.blockchain_id
        }/${this.contract_type.slice(3)}`;

        let retMint;

        if (this.contract_type == "ERC721") {
          retMint = await parula.createArtwork(
            minterWallet,
            this.init_owner_wallet,
            royaltiesTo
          );
        } else {
          // ERC1155
          retMint = await parula.createArtwork1155(
            minterWallet,
            this.init_owner_wallet,
            this.total_supply,
            royaltiesTo
          );
        }

        if (!retMint || !retMint.status) {
          notify({ type: "error", text: "Product add has been rejected" });
          this.$refs.LoadingModal.close();
        } else {
          this.token_id = retMint.tokenId;
          this.metadata_url = `${this.metadata_url}/${retMint.tokenId}`;

          console.log("retMint ==> ", retMint);
          this.transaction_hash = retMint.transactionHash;

          OpenapiAuthService.insert(
            this.title,
            this.description,
            this.total_supply,
            this.centuple_royalties,
            this.image,
            this.thumbnail,
            this.main_content_id,
            this.main_content_type,
            this.sub_content_id,
            this.sub_content_type,
            this.asset_id,
            0, // this.collection_id, admin에서 0으로 넣고 있음...
            this.contract_type,
            1, //this.state, admin에서 1로 넣고 있음...
            this.user_id, // 여기서는 user = artist = owner
            this.user_id, // 여기서는 user = artist = owner
            0, // this.owner_wallet_id admin에서 0으로 넣고 있음...
            this.init_mint_wallet, // user가 여러 개의 wallet을 가지고 있을 수 있어서 mint_wallet과 다를 수도 있다.
            this.init_owner_wallet,
            this.token_id,
            1, // this.metadata_version, admin에서 1로 넣고 있음...
            this.metadata_url,
            retMint.tx,
            this.category
          )
            .then(() => {
              this.$refs.LoadingModal.close();
              this.$refs.ResultModal.open(
                "Success",
                "The artwork has been successfully registered."
              );
            })
            .catch((error) => {
              console.error(error);
              this.$refs.LoadingModal.close();
              notify({ type: "error", text: "An error occur" });
            });
        }
      } catch (error) {
        console.error(error);
        notify({ type: "error", text: "An error occur" });
      } finally {
        this.$refs.LoadingModal.close();
      }
    },

    checkValidation() {
      if (!this.image) {
        notify({ type: "error", text: "The artwork's image is empty" });
        return false;
      } else if (!this.thumbnail) {
        notify({ type: "error", text: "The artwork's thumbnail is empty" });
        return false;
      } else if (!this.title) {
        notify({ type: "error", text: "The artwork's title is empty" });
        return false;
      } else if (!this.description) {
        notify({ type: "error", text: "The artwork's description is empty" });
        return false;
      } else if (!this.category) {
        notify({ type: "error", text: "The category is empty" });
        return false;
      } else if (!this.blockchain_id) {
        notify({ type: "error", text: "The artwork's blockchain-id is empty" });
        return false;
      } else if (!this.contract_type) {
        notify({ type: "error", text: "The artwork's contract-type is empty" });
        return false;
      } else if (!this.asset_id) {
        notify({ type: "error", text: "The artwork's asset-id is empty" });
        return false;
      } else if (
        !this.total_supply ||
        1 > this.total_supply ||
        100000 < this.total_supply
      ) {
        notify({ type: "error", text: "Please check the total-supply" });
        return false;
      } else if (!this.royalties) {
        notify({ type: "error", text: "The artwork's royalties is empty" });
        return false;
      } else if (
        Number((this.royalties * 100).toFixed(2)) < 700 ||
        Number((this.royalties * 100).toFixed(2)) > 999
      ) {
        notify({
          type: "error",
          text: "The royalties's range is 7.00 ~ 9.99%",
        });
        return false;
      } else if (String(this.royalties).indexOf(".") !== -1) {
        if (String(this.royalties).split(".")[1].length > 2) {
          notify({
            type: "error",
            text: "The maximum number of royalties's decimals size is 2",
          });
          return false;
        }
      } else if (!this.nickname) {
        notify({ type: "error", text: "The artist's nickname is empty" });
        return false;
      }

      return true;
    },

    loadUser() {
      OpenapiService.userById(this.user_id)
        .then((res) => {
          this.user_is_creator = res.data.is_creator;
          this.name = res.data.name;
          this.nickname = res.data.nickname;
          this.pen_name = res.data.pen_name;
        })
        .catch((error) => {
          console.error(error);
          notify({ type: "error", text: "Can not load user data." });
        });
    },

    imageUploaded(event) {
      this.main_content_id = event.id;
      this.main_content_type = event.type;
    },

    thumbnailUploaded(event) {
      this.sub_content_id = event.id;
      this.sub_content_type = event.type;
    },

    changeNetwork: function(e) {
      if (e && Number(this.metamask_blockchain_id) != Number(e.chain_id)) {
        notify({
          type: "error",
          text:
            "It is not possible to select a network other than that of the Metamask",
        });
        this.$refs.BlockchainSelector.init();
      } else {
        // this.metamask_blockchain_id = e.id;
        this.blockchain_id = e.chain_id;
      }
    },

    changeType: function(e) {
      this.contract_type = e;
    },

    changeAsset(e) {
      this.asset_id = e.id;
      if (this.contract_type === "ERC721") {
        this.total_supply = 1;
      } else {
        this.total_supply = "";
      }
    },

    setWallet: function(wallet) {
      this.init_owner_wallet = wallet.account;
      // cash("#wallet-selector-modal").modal("hide");
    },

    // checkWallet: async function() {
    //   const provider = await detectEthereumProvider();

    //   if (provider === null) {
    //     notify({
    //       type: "error",
    //       text: "Can not find metamask",
    //     });
    //     return;
    //   }

    //   window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
    //     console.log("res ==> ", res);
    //     this.emitter.emit("CURRENT_ADDRESS", res[0]);
    //   });

    //   // 체인변경이벤트 등록
    //   window.ethereum.on("chainChanged", this.handleChainIdChained);
    //   window.ethereum.on("accountsChanged", this.handleAccountsChanged);
    //   return;
    // },

    handleChainIdChained() {
      this.metamask_blockchain_id = Number(window.ethereum.chainId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";

select {
  padding: 0;
}

.form__adjustment {
  width: 80%;
  margin: 5rem auto 0 auto;
}

.input-design {
  border-width: 1px;
  border-color: #262626;
  width: 75%;
  padding: 0.25rem 0.5rem;
}

::placeholder {
  font-size: 1rem;
  // padding: 0.25rem;
  color: #8a8a8a;
}

.button__green {
  font-weight: 600;
  @include button-radius(8rem, 2.5rem, #20A97F, $white);
}

.button__border__green {
  font-weight: 600;
  @include button-white-radius(8rem, 2.5rem, #20A97F, #20A97F);
  // &:hover {
  //   @include button-radius(10rem, 2rem, $black, $white);
  //   border: 1px, solid, $black;
  // }
}
</style>
